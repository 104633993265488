<template>
  <div v-if="completed" class="section_overview">
    <button class="no-print" style="height: 0" v-if="completed"></button>
    <b-row>
      <b-col cols="3">
        <img class="logo" src="@/assets/images/logo_2.svg" alt="logo"/>
      </b-col>
      <b-col cols="6" class="text-center">
        <h3 style="font-weight: bold">CREDITS REPORT</h3>
        <h5>REMAINING CREDITS:
          <credits-view class="text-success" :amount="headInfo.balance" :currency-details="headInfo.currencyDetails"
                        ligth/>
        </h5>
      </b-col>
      <b-col cols="3" class="text-center">
        <h6>USER: {{ headInfo.name }}</h6>
        <h6>EXPORT DATE: {{ headInfo.created }}</h6>
      </b-col>
    </b-row>

    <table class="table table-bordered">
      <tbody>
      <tr>
        <th class="head" style="white-space: nowrap !important;">DESCRIPTION</th>
        <th class="head">CATEGORY</th>
        <th class="head">TYPE</th>
        <th class="head">MOVEMENT</th>
        <th class="head">VIN</th>
        <th class="head">VEHICLE</th>
        <th class="head">RECEIVED</th>
        <th class="head">USED</th>
        <th class="head">REMAINING</th>
        <th class="head" style="white-space: nowrap !important;">DATE</th>
      </tr>
      <tr v-for="(item, index) in userMovements" :key="index" style="line-height: 0.1vw">
        <td>{{ item.description }}</td>
        <td style="width: 30px"> {{ item.model | overviewCategory(item.movement) }}</td>
        <td>{{ item.type }}</td>
        <td>{{ item.movement }}</td>
        <td>{{ (item.object !== null) ? item.object.VIN : null }}</td>
        <td>{{
            (item.object !== null) ? (item.object.vehicle.name || item.object.vehicle.brand + ' / ' + item.object.vehicle.model) : null
          }}
        </td>
        <td>
          <credits-view class="font-weight-bold text-success" show-lines-zero no-show-logo :amount="item.amount"
                        :currency-details="item.currencyDetails"/>
        </td>
        <td>
          <credits-view class="font-weight-bold text-danger" show-lines-zero no-show-logo :amount="item.used"
                        :currency-details="item.currencyDetails"/>
        </td>
        <td>
          <credits-view class="font-weight-bold" no-show-logo :amount="item.remaining"
                        :currency-details="item.currencyDetails"/>
        </td>
        <td> {{ item.createdAt }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {get} from '@/services/api';
import moment from 'moment';
import CreditsView from '@/components/common/CreditsView';
import {mapState} from 'vuex';

export default {
  name: 'CreditOverviewPdf',
  components: {CreditsView},
  data: () => ({
    userMovements: [],
    rol: null,
    completed: false,
    headInfo: {
      name: '',
      bought: 0,
      used: 0,
      balance: 0,
      created: moment(new Date())
        .format('YYYY-MM-DD'),
      currencyDetails: {}
    },
    config_overview: 'tvs',
    params: {id: null, rol: null}
  }),
  mounted() {
    this.params.id = this.$route.params.id;
    this.params.rol = this.$route.params.rol;
    this.loadConfig().then(() => this.load())
  },
  methods: {
    async loadConfig() {
      const {data} = await get(`site/user-config-overview?id=${this.params.id}`, null, true)
      this.$store.state.geo.showInLocal = ['administrator'].includes(this.params.rol) ? false : data.creditOverview === 'local'
    },
    load() {
      get(`site/export-overview-excel?page=1&limit=50000&id=${this.params.id}&all_balance=false&rol=${this.params.rol}`,
        null, false)
        .then(({data}) => {
          this.headInfo.used = data.used;
          this.headInfo.bought = data.bought;
          this.headInfo.balance = data.remaining;
          this.userMovements = data.data.map(row => {
            let type = '';
            if (!!row.type) {
              type = this.getType(row.type)
            }
            if (row.amount !== null) {
              if (row.amount > 0) {
                row.used = 0
              } else {
                row.used = row.amount
                row.amount = 0;
              }
            }
            row.createdAt = moment(row.createdAt).format('YYYY-MM-DD')
            row.currencyDetails = this.params.rol === 'administrator' ? "" : data.session_currency
            this.headInfo.name = (row.user !== null) ? row.user.fullName : null;
            return {
              ...row,
              type,
              object: row.softwareOrder ? {
                ...row.softwareOrder,
                vehicle: (row.softwareOrder !== null) ? JSON.parse(row.softwareOrder.vehicle) : '',
              } : null,
            };
          });
          this.headInfo.currencyDetails = this.params.rol === 'administrator' ? this.tvsCurrency : data.session_currency;
          this.completed = true;
          this.setCurrency()
        });
    },
    setCurrency() {
      this.$store.commit('geo/setUserCurrency', {
        ...this.$store.state.geo.tvsCurrency,
        locale: this.headInfo.currencyDetails.locale
      })
    },
    getType(type) {
      const parse = JSON.parse(type);
      if (this.params.rol.toString() === 'administrator') {
        type = parse.admin;
      } else if (this.params.rol.toString() === 'distributor') {
        type = parse.distributor;
      } else if (this.params.rol.toString() === 'dealer' || this.params.rol.toString() === 'subdealer') {
        type = parse.dealer;
      }
      return type;
    }
  },
  watch: {
    '$route.params'() {
      this.load();
    }
  },
  computed: mapState('geo', ['tvsCurrency'])
};
</script>
<style scoped lang="scss">

.section_overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center
}

h1, h2, h3, h4, h5, h6 {
  color: black;
}

.head {
  background-color: #e3e3e3;
}

.table td {
  font-size: 11px !important;
}

.tr-header {
  background: black !important;
  height: 4vw !important;
}

.title {
  color: black;
  font-weight: bold
}

.logo {
  width: 100%
}

.text-green {
  font-weight: bold;
  color: green
}

.text-red {
  font-weight: bold;
  color: red
}

@media print {
  .head {
    background-color: #e3e3e3 !important;
  }

  html, body {
    height: 100% !important;
  }
  .no-print {
    display: none !important;
  }

  .body {
    width: 100vw !important;
    height: 100vh !important;
    page-break-after: always;
  }
}

@page {
  size: landscape;
}


</style>

